import * as React from "react";
import Layout from "../components/Layout";

export default function PrivacyCookiePolicyPage() {
  return (
    <Layout pageTitle="Privacy e Cookie Policy">
      <div className="container py-5">
        <h1>CONSENSO INFORMATO AL TRATTAMENTO DEI DATI</h1>
        <h2>
          INFORMATIVA AL TRATTAMENTO DATI UTENTI SITO WEB ART. 13 Regolamento
          (UE) 2016/679 Rev.3.01 del 25/05/2022
        </h2>
        <p>
          I dati personali fornitici verranno trattati con correttezza, liceità
          e trasparenza a tutela della tua riservatezza e di ogni altro diritto,
          in conformità al Regolamento (UE) 2016/679 e Decreto Legislativo 30
          giugno 2003, n.196 così come modificato dal Decreto Legislativo 10
          agosto 2018, n. 101, di seguito GDPR
        </p>
        <h3>A. TITOLARE DEL TRATTAMENTO</h3>
        <p>
          Titolare del trattamento è IT-AL S.n.c. avente sede legale in Via
          Thomas Alva Edison 7 - 06135 Ponte San Giovanni (PG) - tel.
          075.9071910, e-mail: info@it-al.it nella persona del suo Legale
          Rappresentante – Mirco Baldassarri.
        </p>
        <h3>B. DATI PERSONALI TRATTATI</h3>
        <p>
          Nei limiti delle finalità e delle modalità descritte nella presente
          informativa, il Titolare del Trattamento può raccogliere, registrare,
          organizzare, conservare e consultare le seguenti informazioni fornite
          dall'Interessato:
        </p>
        <h4>B.1 in fase di registrazione alla newsletter:</h4>
        <ul>
          <li>Indirizzo e-mail</li>
          <li>Cognome e nome</li>
          <li>Provincia</li>
          <li>Disciplina professionale</li>
        </ul>
        <p>
          Per finalità di sicurezza, gestione tecnica e manutenzione del sito e
          dei servizi collegati verranno acquisiti, registrati e conservati i
          log di accesso e di navigazione [data ed ora, indirizzo IP]. I dati
          verranno conservati per un anno esclusivamente per finalità di
          indagine.
        </p>
        <h3>
          C. FINALITÀ DEL TRATTAMENTO - PER COSA SONO UTILIZZATI I SUOI DATI
          PERSONALI
        </h3>
        <p>
          Il trattamento comprenderà, nel rispetto dei principi stabiliti
          dall’art. 5 del REG. UE 2016/679, tutte le operazioni necessarie al
          perseguimento delle seguenti finalità:
        </p>
        <ol>
          <li>
            adempimento dei vigenti obblighi amministrativo - contabili,
            fiscali, previdenziali e legali, compresi quelli derivanti dalla
            normativa antiriciclaggio;
          </li>
          <li>
            invio via e-mail di newsletter, comunicazioni commerciali e/o
            materiale pubblicitario su servizi resi dal Titolare analoghi a
            quelli oggetto del contratto;
          </li>
          <li>Promozione delle attività svolte da IT-AL S.n.c.;</li>
          <li>
            adempimento di tutti gli obblighi precontrattuali e contrattuali
            relativi alla vendita di prodotti on-line, inclusi il pagamento e
            l’eventuale consegna dei prodotti richiesti;
          </li>
          <li>
            In relazione ai dati relativi alla navigazione, il trattamento è
            necessario finalità di sicurezza, gestione tecnica e manutenzione
            del sito e dei servizi collegati.
          </li>
        </ol>
        <h3>D. BASI LEGALI DEL TRATTAMENTO</h3>
        <p>
          Il Titolare tratterà i dati in conformità ai principi espressi dal
          GDPR ed in assenza di consenso espresso, in quanto:
        </p>
        <ul>
          <li>
            il trattamento è necessario all'esecuzione di un contratto di cui
            l'interessato è parte ovvero all'esecuzione di misure
            precontrattuali adottate su richiesta dello stesso (
            <b>art. 6, par. 1, lett. b</b>);
          </li>
          <li>
            il trattamento è necessario per adempiere un obbligo legale al quale
            è soggetto il titolare del trattamento (
            <b>art. 6, par. 1, lett. c</b>);
          </li>
          <li>
            in relazione ai dati relativi alla navigazione, il trattamento è
            necessario per il perseguimento del legittimo interesse del titolare
            del trattamento (<b>art. 6, par. 1, lett. f</b>);.
          </li>
        </ul>
        <p>
          Ove necessario, ad esempio per l’iscrizione alla newsletter, il
          consenso viene espressamente richiesto. I minori di età pari o
          superiore ai 14 anni possono registrarsi al Sito Web in piena
          autonomia, esprimendo, se ritenuto utile, un valido consenso alla
          ricezione di newsletter via e-mail
        </p>
        <p>
          I minori di età pari o al di sotto dei 13 anni non possono registrarsi
          al Sito Web, salvo consenso esplicito da parte di chi esercita la
          potestà genitoriale. Tale consenso potrà essere validamente espresso
          mediante la procedura guidata durante la fase di iscrizione.
        </p>
        <p>
          Il consenso potrà essere liberamente revocato in qualsiasi momento
          mediante richiesta da inviare ai recapiti indicati ovvero tramite
          cancellazione.
        </p>
        <h3>
          E. NATURA DEL CONFERIMENTO DEI DATI E CONSEGUENZE DEL RIFIUTO DI
          RISPONDERE
        </h3>
        <p>
          Il trattamento da parte del Titolare per le finalità di cui sopra è
          consentito solo a seguito di avvenuta presa visione di tale
          Informativa in sede di registrazione al nostro sito web. Il mancato o
          non corretto conferimento dei dati rende impossibile il trattamento,
          ma non la fruizione dei contenuti pubblici del sito. Il conferimento
          dei dati necessari allo svolgimento del rapporto è facoltativo, ma
          costituisce condizione necessaria ed indispensabile per la fruizione
          dei servizi offerti: un eventuale rifiuto di fornire i dati determina,
          quindi, l’impossibilità di eseguire il servizio richiesto.
        </p>
        <h3>
          F. MODALITÀ DI TRATTAMENTO - COME SONO TRATTATI I SUOI DATI PERSONALI
        </h3>
        <p>
          I dati personali saranno trattati dal Titolare con l’ausilio di
          strumenti atti a registrare e memorizzare i dati stessi e comunque in
          modo tale da garantirne la sicurezza e tutelare la massima
          riservatezza dell’interessato, nel rispetto dei principi di
          correttezza, liceità, trasparenza, pertinenza, completezza e non
          eccedenza.
        </p>
        <p>
          Il Titolare tratterà i dati acquisiti per il tempo strettamente
          necessario al raggiungimento delle finalità sottese al trattamento e
          gli stessi saranno conservati per il tempo in cui il titolare sia
          soggetto agli obblighi di conservazione previsti da norme di legge. La
          verifica sulla obsolescenza dei dati conservati in relazione alle
          finalità per cui sono stati raccolti viene effettuata periodicamente.
        </p>
        <h3>
          G. AMBITO DI COMUNICAZIONE E DIFFUSIONE - CHI PUÒ ACCEDERE AI SUOI
          DATI
        </h3>
        <p>
          Possono venire a conoscenza dei dati personali trattati, in qualità
          Soggetti Autorizzati al Trattamento, il personale dipendente ed
          eventuali collaboratori di IT-AL S.n.c., esclusivamente in relazione
          alle mansioni da loro svolte ed alle finalità sopra esposte.
          <br />
          I dati potranno essere resi accessibili, per le finalità indicate al
          p.to C del presente documento, a Soggetti esterni in qualità di
          Responsabili del trattamento ex art. 28 del GDPR.
          <br />I Suoi dati non saranno diffusi.
        </p>
        <h3>H. TRASFERIMENTO DATI</h3>
        <p>
          I dati personali sono conservati su server ubicati in Italia,
          all’interno dell’Unione Europea.
        </p>
        <h3>I. UTILIZZO E GESTIONE DEI COOKIE</h3>
        <p>
          Questo sito web utilizza i cookie. I cookie sono piccoli file di testo
          che possono essere utilizzati dai siti web per rendere più efficiente
          l'esperienza per l'utente. Utilizziamo cookie tecnici per il corretto
          funzionamento del sito web e analitici per analizzare in modo anonimo,
          ed in forma aggregata, il traffico degli utenti. Questo sito utilizza
          diversi tipi di cookie.
        </p>
        <h3>J. DIRITTI DELL’INTERESSATO</h3>
        <p>
          Nella Sua qualità di interessato, ha i diritti di cui agli artt. 15 -
          22 REG. UE 2016/679 e precisamente i diritti di:
        </p>
        <ol>
          <li>
            ottenere la conferma dell'esistenza o meno di dati personali che La
            riguardano, anche se non ancora registrati, e la loro comunicazione
            in forma intelligibile;
          </li>
          <li>
            ottenere l'indicazione:
            <ul style={{ listStyleType: "none" }}>
              <li>a) dell'origine dei dati personali;</li>
              <li>b) delle finalità e modalità del trattamento;</li>
              <li>
                c) della logica applicata in caso di trattamento effettuato con
                l'ausilio di strumenti elettronici;
              </li>
              <li>
                d) degli estremi identificativi del titolare, dei responsabili e
                del rappresentante designato ai sensi dell’art. 3, paragrafo 1
                del REG. UE 2016/679;
              </li>
              <li>
                e) dei soggetti o delle categorie di soggetti ai quali i dati
                personali possono essere comunicati o che possono venirne a
                conoscenza in qualità di rappresentante designato nel territorio
                dello Stato, di responsabili o incaricati;
              </li>
            </ul>
          </li>
          <li>
            ottenere:
            <ul style={{ listStyleType: "none" }}>
              <li>
                a) l'aggiornamento, la rettificazione ovvero, quando vi ha
                interesse, l'integrazione dei dati;
              </li>
              <li>
                b) la cancellazione, la trasformazione in forma anonima o il
                blocco dei dati trattati in violazione di legge, compresi quelli
                di cui non è necessaria la conservazione in relazione agli scopi
                per i quali i dati sono stati raccolti o successivamente
                trattati;
              </li>
              <li>
                c) l'attestazione che le operazioni di cui alle lettere a) e b)
                sono state portate a conoscenza, anche per quanto riguarda il
                loro contenuto, di coloro ai quali i dati sono stati comunicati
                o diffusi, eccettuato il caso in cui tale adempimento si rivela
                impossibile o comporta un impiego di mezzi manifestamente
                sproporzionato rispetto al diritto tutelato;
              </li>
            </ul>
          </li>
          <li>
            opporsi, in tutto o in parte:
            <ul style={{ listStyleType: "none" }}>
              <li>
                a) per motivi legittimi al trattamento dei dati personali che La
                riguardano, ancorché pertinenti allo scopo della raccolta;
              </li>
              <li>
                b) al trattamento di dati personali che La riguardano a fini di
                invio di materiale pubblicitario o di vendita diretta o per il
                compimento di ricerche di mercato o di comunicazione
                commerciale, mediante l’uso di sistemi automatizzati di chiamata
                senza l’intervento di un operatore mediante e-mail e/o mediante
                modalità di marketing tradizionali mediante telefono e/o posta
                cartacea. Si fa presente che il diritto di opposizione
                dell’interessato, esposto al precedente punto b), per finalità
                di marketing diretto mediante modalità automatizzate si estende
                a quelle tradizionali e che comunque resta salva la possibilità
                per l’interessato di esercitare il diritto di opposizione anche
                solo in parte. Pertanto, l’interessato può decidere di ricevere
                solo comunicazioni mediante modalità tradizionali ovvero solo
                comunicazioni automatizzate oppure nessuna delle due tipologie
                di comunicazione.
              </li>
            </ul>
          </li>
          <li>esporre reclamo all’Autorità Garante.</li>
        </ol>
        <p>
          <b>
            L’interessato può far valere i propri diritti attraverso l’invio di
            esplicita e-mail agli indirizzi/recapiti riportati al p.to A del
            presente documento.
          </b>
        </p>
      </div>
    </Layout>
  );
}
